import axios from 'axios';
import { User } from '../models/user';

class SearchService {
    async searchUser(searchTerm: string): Promise<User[]> {
        const response = await axios.get<{ users: User[] }>(`/search/user/${searchTerm}`);

        return response.data.users;
    }
}

export default SearchService;