import { createModel } from '@rematch/core';
import { RootModel } from '.';
import TagService from '../services/TagService';
import { Dispatch, GlobalState } from './bootstrap';

export type TagCounts = Array<TagCount>
export interface TagCount {
    tag: string;
    count: number;
}

type TagState = {
    mostUsed: TagCounts
}

const tagService = new TagService();

const initialState = {
    mostUsed: []
} as TagState

export const tag = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setMostUsed: (state: TagState, payload: TagCounts) => ({
            ...state,
            mostUsed: payload
        }),
    },
    effects: (dispatch: Dispatch) => ({
        getMostUsed: async (payload: null, state: GlobalState) => {
            const mostUsedTags = await tagService.getMostUsed()
            dispatch.tag.setMostUsed(mostUsedTags)
        },
    })
}) as any;