import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, GlobalState } from '../models/bootstrap'
import RouteService, { FullRoute, Routes, RouteTypes } from '../services/RouteService'
import BillboAvatar from './Avatar'
import Search from './Search'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		position: 'fixed',
		zIndex: 2
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},
	drawerItem: {
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
	},
	list: {
		width: 250
	}
}))

const BillboAppBar = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { t } = useTranslation()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const { currentUser, currentRoute, currentTenant } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser,
		currentRoute: state.environment.currentRoute,
		currentTenant: state.tenant.currentTenant
	}))
	const theme = useTheme()

	const handleMenu = (event: any) => {
		dispatch.environment.navigate(RouteService.getByPath(Routes.profile))
	}

	const toggleDrawer = () => setDrawerOpen(!drawerOpen)

	const handleDrawerItemClick = (route: FullRoute) => {
		dispatch.environment.navigate(route)
		toggleDrawer()
	}

	const handleLogin = () => dispatch.environment.navigate(RouteService.getByPath(Routes.login))

	const starkmacherBillboOnlyRoutes = [RouteService.getByPath(Routes.dataProtection), RouteService.getByPath(Routes.imprint)]

	const allowedRoutes = !currentTenant
		? [RouteService.getByPath(Routes.selectTenant), ...(window.location.host === 'billbo.online' ? starkmacherBillboOnlyRoutes : [])]
		: RouteService.getAllowed(currentUser?.role, RouteTypes.drawer)

	return (
		<div className={classes.root}>
			<AppBar position='fixed'>
				<Toolbar>
					<>
						<IconButton edge='start' className={classes.menuButton} onClick={toggleDrawer}>
							<MenuIcon style={{ fill: theme.palette.getContrastText(theme.palette.primary.main) }} />
						</IconButton>
						<Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer}>
							<List className={classes.list}>
								{allowedRoutes.map((route) => (
									<ListItem button key={route.name} className={classes.drawerItem} onClick={() => handleDrawerItemClick(route)}>
										<ListItemIcon>
											<route.iconComponent />
										</ListItemIcon>
										<ListItemText primary={t(`pages.${route.name}`)} />
									</ListItem>
								))}
							</List>
						</Drawer>
					</>
					<Typography variant='h6' className={classes.title}>
						{currentRoute && t(`pages.${currentRoute.name}`)}
					</Typography>
					<Search />
					{currentUser ? (
						<div>
							<BillboAvatar user={currentUser} onClick={handleMenu} pulse />
						</div>
					) : (
						<Button variant='contained' color='secondary' onClick={handleLogin}>
							{t('basic.login')}
						</Button>
					)}
				</Toolbar>
			</AppBar>
		</div>
	)
}

export default BillboAppBar
