import axios from 'axios'
import { RegisterUserData, User, UserData } from '../models/user'

class UserService {
	async create(userData: UserData): Promise<string> {
		const response = await axios.post<User>('/user/create', { ...userData })

		return response.data._id
	}

	async getAll(): Promise<Array<User>> {
		const response = await axios.get<Array<User>>('/user/')

		return response.data
	}

	async getById(_id: string): Promise<User> {
		const response = await axios.get<{ user: User }>(`/user/${_id}`)

		return response.data.user
	}

	async login(email: string, password: string): Promise<LoginSuccessResponse> {
		const response = await axios.post<LoginSuccessResponse>('/user/login', { email, password })

		localStorage.setItem('refreshToken', response.data.refreshToken)
		axios.defaults.headers.common['Authorization'] = response.data.accessToken

		return response.data
	}

	async getNewAccessToken(): Promise<TokenSuccessResponse | ErrorResponse> {
		const refreshToken = localStorage.getItem('refreshToken')
		const response = await axios.post<TokenSuccessResponse>('/user/accessToken', { refreshToken })

		localStorage.setItem('refreshToken', response.data.refreshToken)
		axios.defaults.headers.common['Authorization'] = response.data.accessToken

		return {
			accessToken: response.data.accessToken,
			refreshToken: response.data.refreshToken,
			user: response.data.user
		}
	}

	async update(userData: User): Promise<User> {
		const response = await axios.put<{ updatedUser: User }>(`/user/${userData._id}`, { ...userData })

		return response.data.updatedUser
	}

	async uploadAvatar(avatar: Blob, name: string): Promise<string> {
		let formData = new FormData()
		let file = new File([avatar], name)
		formData.append('avatar', file)
		const response = await axios.post<any>('/user/uploadAvatar', formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})

		return response.data.avatarUrl
	}

	async adminInvite(payload: {
		email: string
		asEco: boolean
		tenantId: string
	}): Promise<{ inviteSent: boolean; inviteLink: string } | ErrorResponse> {
		const response = await axios.post<{ inviteSent: boolean; inviteLink: string }>('/admin/inviteUser', {
			receiver: payload.email,
			asEco: payload.asEco,
			tenantId: payload.tenantId
		})

		return response.data
	}

	async invite(payload: { email: string; asEco: boolean }): Promise<{ inviteSent: boolean; inviteLink: string } | ErrorResponse> {
		const response = await axios.post<{ inviteSent: boolean; inviteLink: string }>('/user/invite', {
			receiver: payload.email,
			asEco: payload.asEco
		})

		return response.data
	}

	async register(payload: RegisterUserData): Promise<LoginSuccessResponse | ErrorResponse> {
		const response = await axios.post<LoginSuccessResponse>('/user/register', payload)

		localStorage.setItem('refreshToken', response.data.refreshToken)
		axios.defaults.headers.common['Authorization'] = response.data.accessToken

		return response.data
	}

	async changePassword(payload: ChangePasswordPayload): Promise<User> {
		const response = await axios.post('/user/changePassword', { oldPassword: payload.oldPassword, newPassword: payload.newPassword })

		return response.data
	}

	async sendRecovery(email: string): Promise<{ recoverySent: boolean; recoveryLink: string } | ErrorResponse> {
		const response = await axios.post('/user/sendRecovery', { email })

		return response.data
	}

	async recoverAccount(payload: RecoverAccountPayload): Promise<{ recoverySuccess: boolean } | ErrorResponse> {
		const response = await axios.post('/user/recover', payload)

		return response.data
	}
}

export interface RecoverAccountPayload {
	email: string
	password: string
	recoveryHash: string
}

export interface ChangePasswordPayload {
	oldPassword: string
	newPassword: string
}

export interface LoginSuccessResponse {
	accessToken: string
	refreshToken: string
	user: User
}

export interface TokenSuccessResponse {
	accessToken: string
	refreshToken: string
	user: User
}

export interface ErrorResponse {
	message: string
}

export default UserService
