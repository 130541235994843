import axios from 'axios';
import { TagCounts } from '../models/tag';

class TagService {
    async getMostUsed(): Promise<TagCounts> {
        const response = await axios.get<{ tags: TagCounts }>(`/tag/mostUsed?hot=true`);

        return response.data.tags;
    }
}

export default TagService;