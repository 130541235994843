import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../models/bootstrap';

const BillboSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch<Dispatch>();

  React.useEffect(() => {
    !!enqueueSnackbar && dispatch.environment.setEnqueueSnack(enqueueSnackbar)
    !!closeSnackbar && dispatch.environment.setCloseSnack(closeSnackbar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, closeSnackbar])

  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default BillboSnackbar