import axios from 'axios';
import { Tenant, TenantData, TenantStats } from '../models/tenant';

class TenantService {
    async create(tenantData: TenantData): Promise<string> {
        const response = await axios.post<Tenant>('/admin/tenant/create', { ...tenantData });

        return response.data._id;
    }

    async getAll(): Promise<Array<Tenant>> {
        const response = await axios.get<Array<Tenant>>('/admin/tenant');

        return response.data;
    }

    async getAllPublic(): Promise<Array<Tenant>> {
        const response = await axios.get<Array<Tenant>>('/tenant/public');

        return response.data;
    }

    async getMine(): Promise<Array<Tenant>> {
        const response = await axios.get<Array<Tenant>>('/tenant/mine');

        return response.data;
    }

    async getById(_id: string): Promise<Tenant> {
        const response = await axios.get<{ tenant: Tenant }>(`/tenant/${_id}`);

        return response.data.tenant;
    }

    async updateHeaderContent(tenantId: string, headerContent: string): Promise<Tenant> {
        const response = await axios.put(`/tenant/${tenantId}`, { headerContent })

        return response.data.tenant;
    }

    async changeName(tenantId: string, newName: string): Promise<Tenant> {
        const response = await axios.put(`/admin/changeTenantName/${tenantId}`, { newName })

        return response.data.tenant;
    }

    async changeColors(payload: { primaryColor?: string, secondaryColor?: string }): Promise<Tenant> {
        const response = await axios.post(`/tenant/changeColors`, { primaryColor: payload.primaryColor, secondaryColor: payload.secondaryColor })

        return response.data.tenant;
    }

    async exportFullFeed(payload: void): Promise<string> {
        return (await axios.get(`/tenant/exportFullFeed`)).data
    }

    async getStats(): Promise<TenantStats> {
        const response = await axios.get(`/tenant/stats`)

        return response.data;
    }  
}

export default TenantService;