import { createModel } from '@rematch/core';
import html2canvas from 'html2canvas';
import { RootModel } from '.';
import { mergeAndSort } from '../helpers/mergeAndSort';
import i18n from '../i18n';
import StoryService from '../services/StoryService';
import { Dispatch, GlobalState } from './bootstrap';
import { Project } from './project';
import { User } from './user';

export interface StoryComment extends StoryCommentData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

interface StoryCommentData {
    body: string;
    user: string | User;
}

export interface Story extends StoryData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface StoryData {
    title: string;
    body: string;
    project?: string|Project;
    user: string|User;
    tags: string[];
    likes?: string[]; // UserIds
    comments?: string[] | StoryComment[];
}

type StoryState = {
    currentStory: Story | null,
    storiesByUserId: { [userId: string]: Story[] };
    storiesByCreated: Story[];
    storiesByCreatedHasMore: boolean;
    filterStoriesByTag: string | null;
    exportLoadingForStoryId: string | null;
}

const storyService = new StoryService();

const initialState = {
    currentStory: null,
    storiesByUserId: {},
    storiesByCreated: [],
    storiesByCreatedHasMore: true,
    filterStoriesByTag: null,
    exportLoadingForStoryId: null,
} as StoryState

// This custom reducer takes the state, an updatedStory (API response after update call) and the key which should be updated (e.g. likes -> Take likes of updatedStory and replace them in the state)
const storySubEntityReducer = (state: StoryState, updatedStory: Story, entityKey: keyof StoryData) => {
    const newFeed = state.storiesByCreated?.map(s => s._id === updatedStory._id ? {
        ...s,
        [entityKey]: updatedStory[entityKey]
    } : s) ?? []
    const userId = typeof updatedStory.user === 'string' ? updatedStory.user : updatedStory.user._id
    const currentUserStories = state.storiesByUserId[userId]

    let newState = {
        ...state,
        storiesByCreated: newFeed
    }

    if (currentUserStories) {
        newState.storiesByUserId = {
            ...state.storiesByUserId,
            [userId]: currentUserStories.map(s => s._id === updatedStory._id ? {
                ...s,
                [entityKey]: updatedStory[entityKey]
            } : s)
        }
    }

    if (state.currentStory?._id === updatedStory._id) {
        newState.currentStory = { ...state.currentStory, [entityKey]: updatedStory[entityKey] }
    }

    return newState
}

export const story = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setStoriesByUserId: (state: StoryState, payload: {userId: string, stories: Story[]}) => ({
            ...state,
            storiesByUserId: {
                ...state.storiesByUserId,
                [payload.userId]: payload.stories
            }
        }),
        setStoriesByCreated: (state: StoryState, newStories: Story[]) => ({ ...state, storiesByCreated: mergeAndSort(state.storiesByCreated, newStories) }),
        setStoriesByCreatedWiped: (state: StoryState, newStories: Story[]) => ({ ...state, storiesByCreated: newStories }),
        setStoriesByCreatedHasMore: (state: StoryState, hasMore: boolean) => ({ ...state, storiesByCreatedHasMore: hasMore }),
        setCurrentStory: (state: StoryState, story: Story) => ({ ...state, currentStory: story }),
        setFilterStoriesByTag: (state: StoryState, tag: string) => ({ ...state, filterStoriesByTag: tag }),
        setStoryLikes: (state: StoryState, updatedStory: Story) => storySubEntityReducer(state, updatedStory, 'likes'),
        setStoryComments: (state: StoryState, updatedStory: Story) => storySubEntityReducer(state, updatedStory, 'comments'),
        setExportLoadingForStoryId: (state: StoryState, storyId: string) => ({ ...state, exportLoadingForStoryId: storyId }),
    },
    effects: (dispatch: Dispatch) => ({
        createStory: async (storyData: StoryData, state: GlobalState) => {
            try {
                await storyService.create(storyData)
                dispatch.environment.enqueueSnack({message: i18n.t('story.created'), options: { variant: 'success' }})
                dispatch.story.getStoriesByUserId({})
                dispatch.story.getStoriesByCreated({ fromStart: true })
            } catch(e) {
                dispatch.environment.enqueueSnack({message: i18n.t('story.creationFailed'), options: { variant: 'error' }})
            }
        },
        getStoriesByUserId: async (payload: { userId?: string }, state: GlobalState) => {
            const userId = payload.userId ?? state.user.currentUser._id
            const stories = await storyService.getByUserId(userId)
            dispatch.story.setStoriesByUserId({ userId, stories })
        },
        getStoriesByCreated: async (payload: { fromStart?: boolean, wipe?: boolean }, state: GlobalState) => {
            const response = await storyService.getByCreated({
                start: payload.fromStart ? 0 : state.story.storiesByCreated.length,
                tag: state.story.filterStoriesByTag
            })
            response.stories.length < response.limit && dispatch.story.setStoriesByCreatedHasMore(false)
            dispatch.story.setFilterStoriesByTag(state.story.filterStoriesByTag)
            payload.wipe ? dispatch.story.setStoriesByCreatedWiped(response.stories) : dispatch.story.setStoriesByCreated(response.stories)
        },
        getStory: async (_id: string, state: GlobalState) => {
            const story = await storyService.getById(_id)
            dispatch.story.setCurrentStory(story)
        },
        updateStory: async (storyData: Story, state: GlobalState) => {
            const updatedStory = await storyService.update(storyData)
            dispatch.story.setStoriesByUserId({
                userId: state.user.currentUser._id,
                stories: state.story.storiesByUserId[state.user.currentUser._id].map((s: Story) => s._id === updatedStory._id ? updatedStory : s)
            })
        },
        likeStory: async (storyId: string, state: GlobalState) => {
            const updatedStory = await storyService.like(storyId)
            dispatch.story.setStoryLikes(updatedStory)
        },
        unlikeStory: async (storyId: string, state: GlobalState) => {
            const updatedStory = await storyService.unlike(storyId)
            dispatch.story.setStoryLikes(updatedStory)
        },
        commentStory: async (payload: { storyId: string, body: string }, state: GlobalState) => {
            const updatedStory = await storyService.comment(payload.storyId, payload.body)
            dispatch.story.setStoryComments(updatedStory)
        },
        deleteStoryComment: async (payload: { storyId: string, commentId: string }, state: GlobalState) => {
            const updatedStory = await storyService.deleteComment(payload.storyId, payload.commentId)
            dispatch.story.setStoryComments(updatedStory)
            dispatch.environment.enqueueSnack({ message: i18n.t('story.deleteCommentSuccess'), options: { variant: 'success' }})
        },
        exportStory: async (storyId: string, state: GlobalState) => {
            dispatch.story.setExportLoadingForStoryId(storyId)
            // Set timeout is needed for the state to reflect i guess? Putting this into a different effect doesn't work somehow..
            setTimeout(async () => {
                const canvas = await html2canvas(document.getElementById(storyId)!, {
                    width: document.getElementById(storyId)!.clientWidth,
                    height: document.getElementById(storyId)!.clientHeight,
                    useCORS: true,
                    allowTaint: false
                })
                const image = canvas.toDataURL()
                const downloadLink = document.createElement('a')
                const now = new Date()
                downloadLink.download = `${state.tenant.currentTenant.name}_${storyId}_${now.toLocaleDateString()}_${now.toLocaleTimeString()}_post_export.png`
                downloadLink.href = image
                downloadLink.click()
                downloadLink.remove()
                dispatch.story.setExportLoadingForStoryId(null)
            }, 1)
        },
    })
}) as any;