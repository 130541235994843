import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { Dispatch, GlobalState } from './bootstrap';

export enum Dialogs {
    createProject = 'createProject',
    createStory = 'createStory',
    viewStory = 'viewStory',
    viewProject = 'viewProject',
    updateTenantHeader = 'updateTenantHeader',
    viewUser = 'viewUser',
    adminManageTenant = 'adminManageTenant'
}

type DialogState = {
    currentDialog: Dialogs | null;
    open: boolean;
    entityId: string | null;
    editData: any;
}

export const dialog = createModel<RootModel>()({
    state: {
        currentDialog: null,
        open: false,
        entityId: null,
        editData: null,
    } as DialogState,
    reducers: {
        setCurrentDialog: (state: DialogState, currentDialog: Dialogs) => ({
            ...state,
            currentDialog
        }),
        setOpen: (state: DialogState, open: boolean) => ({
            ...state,
            open
        }),
        setEntityId: (state: DialogState, entityId: string) => ({
            ...state,
            entityId
        }),
        setEditData: (state: DialogState, editData: any) => ({
            ...state,
            editData
        }),
    },
    effects: (dispatch: Dispatch) => ({
        openDialog: async (payload: { dialog: Dialogs, entityId?: string, editData?: any }, state: GlobalState) => {
            dispatch.dialog.setCurrentDialog(payload.dialog)
            dispatch.dialog.setOpen(true)
            payload.entityId && dispatch.dialog.setEntityId(payload.entityId)
            payload.editData && dispatch.dialog.setEditData(payload.editData)
        },
        closeDialog: async (payload: null, state: GlobalState) => {
            dispatch.dialog.setOpen(false)
            dispatch.dialog.setCurrentDialog(null)
            dispatch.dialog.setEntityId(null)
            dispatch.dialog.setEditData(null)
        }
    })
}) as any;