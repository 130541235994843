import { createModel } from '@rematch/core'
import { RootModel } from '.'
import i18n from '../i18n'
import RouteService, { Routes } from '../services/RouteService'
import UserService, { ChangePasswordPayload, RecoverAccountPayload, TokenSuccessResponse } from '../services/UserService'
import { Dispatch, GlobalState } from './bootstrap'
import { Project } from './project'
import { Story } from './story'
import axios from 'axios'

export enum Role {
	user = 'user',
	eco = 'eco',
	admin = 'admin'
}

export interface User extends UserData {
	_id: string
	createdAt: string
	updatedAt: string
}

export interface FullUser extends User {
	stories: Story[]
	projects: Project[]
}

export interface RegisterUserData {
	firstName: string
	lastName: string
	displayName: string
	password: string
	inviteHash: string
}

export interface UserData {
	firstName: string
	lastName: string
	displayName: string
	email: string
	role: Role
	tenants: string[]
	avatar?: string
}

type UserState = {
	currentUser: User | null
	accessToken: string | null
	users: Array<User>
	currentUserLoading: boolean
	usersById: {
		[userId: string]: User
	}
}

const userService = new UserService()

const initialState = {
	currentUser: null,
	accessToken: null,
	users: [],
	currentUserLoading: true,
	usersById: {}
} as UserState

export const user = createModel<RootModel>()({
	state: initialState,
	reducers: {
		reset: () => ({ ...initialState }),
		setAccessToken: (state: UserState, accessToken: string) => ({
			...state,
			accessToken
		}),
		setCurrentUser: (state: UserState, currentUser: User) => ({
			...state,
			currentUser
		}),
		setUsers: (state: UserState, users: Array<User>) => ({
			...state,
			users
		}),
		setCurrentUserLoading: (state: UserState, currentUserLoading: boolean) => ({
			...state,
			currentUserLoading
		}),
		setAvatarUrl: (state: UserState, avatarUrl: string) => ({
			...state,
			currentUser: {
				...state.currentUser!,
				avatar: avatarUrl
			}
		}),
		setUsersById: (state: UserState, newUser: User) => ({
			...state,
			usersById: {
				...state.usersById,
				[newUser._id]: newUser
			}
		})
	},
	effects: (dispatch: Dispatch) => ({
		create: async (userData: UserData, state: GlobalState) => {
			await userService.create(userData)
		},
		getAllUsers: async (payload: null, state: GlobalState) => {
			const users = await userService.getAll()

			dispatch.user.setUsers(users)
		},
		getCurrentUser: async (_id: string, state: GlobalState) => {
			dispatch.user.setCurrentUserLoading(true)
			const user = await userService.getById(_id)
			dispatch.user.setCurrentUserLoading(false)
			dispatch.user.setCurrentUser(user)
		},
		login: async (payload: { email: string; password: string }, state: GlobalState) => {
			try {
				const response = await userService.login(payload.email, payload.password)
				dispatch.environment.enqueueSnack({
					message: i18n.t('user.welcomeBack', { name: response.user.displayName }),
					options: { variant: 'success' }
				})
				dispatch.user.setCurrentUser(response.user)
				dispatch.user.setAccessToken(response.accessToken)
				!!localStorage.getItem('tenantPrimaryColor') && localStorage.removeItem('tenantPrimaryColor')
				!!localStorage.getItem('tenantSecondaryColor') && localStorage.removeItem('tenantSecondaryColor')

				const localTenantId = localStorage.getItem('tenantId')
				if (localTenantId) {
					return dispatch.tenant.selectCurrentTenant(localTenantId)
				}

				if (response.user.tenants && response.user.tenants.length > 1) {
					dispatch.environment.navigate(RouteService.getByPath(Routes.selectTenant))
				} else {
					dispatch.tenant.selectCurrentTenant(response.user.tenants[0])
				}
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('user.loginFailed'), options: { variant: 'error' } })
			}
		},
		getNewAccessToken: async (payload: void, state: GlobalState) => {
			// We need to return if we have to select a tenant, otherwise the init logic cant know if and where to navigate to
			try {
				const sessionTenantId = localStorage.getItem('tenantId')
				const isPublicTenant = localStorage.getItem('isPublicTenant') === 'true'

				if (sessionTenantId && isPublicTenant && !state.user.currentUser) {
					!state.tenant.currentTenant && (await dispatch.tenant.selectCurrentTenant(sessionTenantId))
				}

				const response = await userService.getNewAccessToken()
				const { accessToken, user } = response as TokenSuccessResponse

				dispatch.user.setCurrentUserLoading(false)
				dispatch.user.setAccessToken(accessToken)
				dispatch.user.setCurrentUser(user)

				if (sessionTenantId) {
					!state.tenant.currentTenant && dispatch.tenant.selectCurrentTenant(sessionTenantId)
					return false
				}

				if (user.tenants && user.tenants.length > 1) {
					dispatch.environment.navigate(RouteService.getByPath(Routes.selectTenant))
					return true
				}

				dispatch.tenant.selectCurrentTenant(user.tenants[0])
				return false
			} catch (e) {
				if (!RouteService.isOnAnonymousPage()) {
					dispatch.environment.navigate(RouteService.getByPath(Routes.login))
				}
			}
		},
		logout: async (payload: null, state: GlobalState) => {
			localStorage.removeItem('refreshToken')
			localStorage.removeItem('tenantId')
			localStorage.removeItem('isPublicTenant')
			delete axios.defaults.headers.common['x-billbo-tenant-id']
			delete axios.defaults.headers.common['Authorization']
			!!localStorage.getItem('tenantPrimaryColor') && localStorage.removeItem('tenantPrimaryColor')
			!!localStorage.getItem('tenantSecondaryColor') && localStorage.removeItem('tenantSecondaryColor')
			dispatch.user.reset()
			dispatch.story.reset()
			dispatch.project.reset()
			dispatch.tenant.reset()
			dispatch.tag.reset()
			dispatch.environment.navigate(RouteService.getByPath(Routes.login))
		},
		update: async (payload: User, state: GlobalState) => {
			const updatedUser = await userService.update(payload)

			updatedUser && dispatch.user.setCurrentUser(updatedUser)
		},
		uploadAvatar: async (payload: { avatar: Blob; name: string }, state: GlobalState) => {
			const avatarUrl = await userService.uploadAvatar(payload.avatar, payload.name)

			avatarUrl && dispatch.user.setAvatarUrl(avatarUrl)
		},
		adminInvite: async (payload: { email: string; asEco: boolean; tenantId: string }, state: GlobalState) => {
			try {
				const response = (await userService.adminInvite(payload)) as { inviteLink: string }
				dispatch.environment.enqueueSnack({ message: i18n.t('eco.invitationSent') })
				return response
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('eco.invitationFailed'), options: { variant: 'error' } })
			}
		},
		invite: async (payload: { email: string; asEco: boolean }, state: GlobalState) => {
			try {
				const response = (await userService.invite(payload)) as { inviteLink: string }
				dispatch.environment.enqueueSnack({ message: i18n.t('eco.invitationSent') })
				return response
			} catch (e) {
				console.log(e)
				dispatch.environment.enqueueSnack({ message: i18n.t('eco.invitationFailed'), options: { variant: 'error' } })
			}
		},
		register: async (payload: RegisterUserData, state: GlobalState) => {
			try {
				const response = await userService.register(payload)
				const { accessToken, user } = response as TokenSuccessResponse

				dispatch.environment.enqueueSnack({ message: i18n.t('user.welcome', { name: payload.displayName }) })
				dispatch.user.setCurrentUser(user)
				dispatch.user.setAccessToken(accessToken)

				if (user.tenants && user.tenants.length > 1) {
					dispatch.environment.navigate(RouteService.getByPath(Routes.selectTenant))
				} else {
					dispatch.tenant.selectCurrentTenant(user.tenants[0])
				}
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('user.registrationFailed'), options: { variant: 'error' } })
			}
		},
		getUserById: async (userId: string, state: GlobalState) => {
			const user = await userService.getById(userId)

			dispatch.user.setUsersById(user)
		},
		changePassword: async (payload: ChangePasswordPayload, state: GlobalState): Promise<boolean> => {
			try {
				await userService.changePassword(payload)

				dispatch.environment.enqueueSnack({ message: i18n.t('user.passwordChanged'), options: { variant: 'success' } })
				return true
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('user.passwordChangeFailed'), options: { variant: 'error' } })
				return false
			}
		},
		sendRecovery: async (email: string, state: GlobalState) => {
			try {
				const response = (await userService.sendRecovery(email)) as any

				dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryMailSent'), options: { variant: 'success' } })
				return response
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryMailFailed'), options: { variant: 'error' } })
			}
		},
		recoverAccount: async (payload: RecoverAccountPayload, state: GlobalState) => {
			try {
				await userService.recoverAccount(payload)

				dispatch.environment.enqueueSnack({ message: i18n.t('user.recoverySuccess'), options: { variant: 'success' } })
				setTimeout(() => {
					dispatch.environment.navigate(RouteService.getByPath(Routes.login))
				}, 3000)
			} catch (e) {
				dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryFailed'), options: { variant: 'error' } })
			}
		}
	})
}) as any
