import { createModel } from '@rematch/core';
import { RootModel } from '.';
import SearchService from '../services/SearchService';
import { Dispatch, GlobalState } from './bootstrap';
import { User } from './user';

type SearchState = {
    searchTerm: string;
    usersLoading: boolean;
    usersResult: User[];
}

const searchService = new SearchService();

const initialState = {
    searchTerm: '',
    usersLoading: false,
    usersResult: []
} as SearchState

let tid = 0;

export const search = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setSearchTerm: (state: SearchState, searchTerm: string) => ({
            ...state,
            searchTerm
        }),
        setUsersResult: (state: SearchState, usersResult: User[]) => ({
            ...state,
            usersResult
        }),
        setUsersLoading: (state: SearchState, usersLoading: boolean) => ({
            ...state,
            usersLoading
        }),
    },
    effects: (dispatch: Dispatch) => ({
        searchUsers: async (payload: void, state: GlobalState) => {
            if (!state.search.searchTerm) return;
            try {
                const usersResult = await searchService.searchUser(state.search.searchTerm)
                dispatch.search.setUsersResult(usersResult)
            } catch (e) {
                // Do nothing.
            }
            
            dispatch.search.setUsersLoading(false)
        },
        updateSearchTerm: async (searchTerm: string, state: GlobalState) => {
            dispatch.search.setSearchTerm(searchTerm);
            searchTerm ? dispatch.search.setUsersLoading(true) : dispatch.search.setUsersResult([]);
            (() => {
                clearTimeout(tid)
                tid = window.setTimeout(() => dispatch.search.searchUsers(), 400)
            })()
        },
    })
}) as any;