import { CircularProgress, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}))

const Spinner = () => {
    const classes = useStyles()

    return <div className={classes.spinner}><CircularProgress/></div>
}

export default Spinner