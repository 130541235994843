import { Models } from "@rematch/core"
import { dialog } from './dialog'
import { environment } from './environment'
import { project } from './project'
import { story } from './story'
import { tag } from './tag'
import { tenant } from './tenant'
import { user } from './user'
import { search } from './search'

export interface RootModel extends Models<RootModel> {
    user: typeof user;
    tenant: typeof tenant;
    environment: typeof environment;
    dialog: typeof dialog;
    project: typeof project;
    story: typeof story;
    tag: typeof tag;
    search: typeof search;
}

export const models: RootModel = {
    user,
    tenant,
    environment,
    dialog,
    project,
    story,
    tag,
    search
}