import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from './App';
import './i18n';
import './index.css';
import { store } from './models/bootstrap';
import reportWebVitals from './reportWebVitals';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.interceptors.response.use((response) => response, (error) => {
//   store.dispatch.environment.enqueueSnack({ message: error.response?.data?.message ?? 'Something went wrong.', options: { variant: 'error' }})
//   return Promise.reject(error.message);
// });

export const generateClassName = createGenerateClassName({
  seed: 'billbo--',
  productionPrefix: 'billbo--',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <App />
      </StylesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
