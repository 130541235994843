import { CircularProgress, InputBase, Popper } from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { useRef, useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from '../models/bootstrap'
import { User } from '../models/user'
import BillboAvatar from './Avatar'

const useStyles = makeStyles((theme) => ({
	root: {
		marginRight: theme.spacing(1),
		maxWidth: '50vw'
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: lighten(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: lighten(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2)
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.dark
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '0ch', // As small as possible
		[theme.breakpoints.up('sm')]: {
			width: '10ch'
		},
		'&:focus': {
			width: '12ch',
			[theme.breakpoints.up('sm')]: {
				width: '20ch'
			}
		},
		color: theme.palette.primary.dark
	},
	paper: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		boxSizing: 'border-box',
		backgroundColor: theme.palette.background.paper,
		minWidth: '20ch',
		borderRadius: 4
	},
	searchResultItem: {
		paddingBottom: theme.spacing(1)
	}
}))

const Search: React.FC = () => {
	const classes = useStyles()
	const searchBarRef = useRef<any>()
	const [open, setOpen] = useState(false)
	const id = open ? 'transitions-popper' : undefined
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { searchTerm, usersResult, currentUser, usersLoading } = useSelector((state: GlobalState) => ({
		searchTerm: state.search.searchTerm,
		usersResult: state.search.usersResult,
		currentUser: state.user.currentUser,
		usersLoading: state.search.usersLoading
	}))

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch.search.updateSearchTerm(e.currentTarget.value)
		setOpen(!!e.currentTarget.value)
	}

	const handleClickAway = (e: any) => {
		dispatch.search.updateSearchTerm('')
		setOpen(false)
	}

	if (!currentUser) return null

	return (
		<div className={classes.root}>
			<ClickAwayListener onClickAway={handleClickAway}>
				<div>
					<div className={classes.search} ref={searchBarRef}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							placeholder={t('basic.search')}
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput
							}}
							type='search'
							autoComplete='new-password'
							onChange={handleSearchInputChange}
							value={searchTerm}
						/>
					</div>
					<Popper id={id} open={open} anchorEl={searchBarRef.current} style={{ zIndex: 1000 }}>
						<div className={classes.paper}>
							{usersLoading ? (
								<CircularProgress />
							) : usersResult.length > 0 ? (
								usersResult.map((u: User) => (
									<div className={classes.searchResultItem} key={u._id}>
										<BillboAvatar user={u} showName />
									</div>
								))
							) : (
								<div className={classes.searchResultItem}>{t('basic.noResults')}</div>
							)}
						</div>
					</Popper>
				</div>
			</ClickAwayListener>
		</div>
	)
}

export default Search
