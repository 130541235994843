import axios from 'axios';
import { Story, StoryData } from '../models/story';

class StoryService {
    async create(storyData: StoryData): Promise<string> {
        const response = await axios.post<Story>('/story/create', { ...storyData });

        return response.data._id;
    }

    async getAll(): Promise<Array<Story>> {
        const response = await axios.get<{ stories: Array<Story> }>('/story/');

        return response.data.stories;
    }

    async getById(_id: string): Promise<Story> {
        const response = await axios.get<{ story: Story }>(`/story/${_id}`);

        return response.data.story;
    }

    async getByUserId(userId: string): Promise<Array<Story>> {
        const response = await axios.get<{ stories: Array<Story> }>(`/story/byUserId/${userId}`);

        return response.data.stories;
    }

    async getByProjectId(projectId: string): Promise<Array<Story>> {
        const response = await axios.get<{ stories: Array<Story> }>(`/story/byProjectId/${projectId}`);

        return response.data.stories;
    }

    async getByCreated(payload: { start: number, limit?: number, tag?: string }): Promise<PagedStories> {
        let route = '/story/byCreated'
        route += `?start=${payload.start ?? 0}`
        if (payload.limit) route += `&limit=${payload.limit}`
        if (payload.tag) route += `&tag=${payload.tag}`
        
        const response = await axios.get(route);

        return response.data;
    }

    async update(storyData: Story): Promise<Story> {
        const response = await axios.put<{ updatedStory: Story }>(`/story/${storyData._id}`, storyData);

        return response.data.updatedStory;
    }

    async like(storyId: string): Promise<Story> {
        const response = await axios.get<{ updatedStory: Story }>(`/story/like/${storyId}`);

        return response.data.updatedStory;
    }

    async unlike(storyId: string): Promise<Story> {
        const response = await axios.get<{ updatedStory: Story }>(`/story/unlike/${storyId}`);

        return response.data.updatedStory;
    }

    async comment(storyId: string, body: string): Promise<Story> {
        const response = await axios.post<{ updatedStory: Story }>(`/story/comment/${storyId}`, { body });

        return response.data.updatedStory;
    }

    async deleteComment(storyId: string, commentId: string): Promise<Story> {
        const response = await axios.delete<{ updatedStory: Story }>(`/story/comment/${storyId}/${commentId}`);

        return response.data.updatedStory;
    }
}

interface PagedStories {
    stories: Story[];
    start: number;
    limit: number;
}

export default StoryService;