import { IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Dialogs } from '../models/dialog'

const CreateProjectDialog = React.lazy(() => import('./CreateProjectDialog'))
const CreateStoryDialog = React.lazy(() => import('./CreateStoryDialog'))
const ViewStoryDialog = React.lazy(() => import('./ViewStoryDialog'))
const ViewProjectDialog = React.lazy(() => import('./ViewProjectDialog'))
const UpdateTenantHeaderDialog = React.lazy(() => import('./UpdateTenantHeaderDialog'))
const ViewUserDialog = React.lazy(() => import('./ViewUserDialog'))
const AdminManageTenantDialog = React.lazy(() => import('./AdminManageTenantDialog'))

const useStyles = makeStyles((theme) => ({
	dialogPaper: {
		minHeight: '50vh'
	}
}))

const BillboDialog = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { currentDialog, open } = useSelector((state: GlobalState) => ({
		currentDialog: state.dialog.currentDialog,
		open: state.dialog.open
	}))
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleClose = () => dispatch.dialog.closeDialog()

	const getDialogContent = (currentDialog: Dialogs) => {
		switch (currentDialog) {
			case Dialogs.createProject:
				return <CreateProjectDialog />
			case Dialogs.createStory:
				return <CreateStoryDialog />
			case Dialogs.viewStory:
				return <ViewStoryDialog />
			case Dialogs.viewProject:
				return <ViewProjectDialog />
			case Dialogs.updateTenantHeader:
				return <UpdateTenantHeaderDialog />
			case Dialogs.viewUser:
				return <ViewUserDialog />
			case Dialogs.adminManageTenant:
				return <AdminManageTenantDialog />
			default:
				return (
					<>
						<IconButton edge='start' color='inherit' onClick={() => dispatch.dialog.closeDialog()} aria-label='close'>
							<CloseIcon />
						</IconButton>
						<Typography variant='body1'>"This is not the dialog you are looking for."</Typography>
					</>
				)
		}
	}

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen={isMobile}
				fullWidth
				maxWidth='sm'
				disableEnforceFocus
				classes={{ paper: classes.dialogPaper }}>
				<Suspense fallback={<Spinner />}>{open && getDialogContent(currentDialog)}</Suspense>
			</Dialog>
		</div>
	)
}

export default BillboDialog
