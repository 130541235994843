import { Link, Typography } from '@material-ui/core'
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React, { Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import Background from './assets/images/FractalBillboAusschnittKleiner.svg'
import BillboAppBar from './components/AppBar'
import BillboRedirect from './components/Redirect'
import BillboSnackbar from './components/Snackbar'
import Spinner from './components/Spinner'
import BillboDialog from './dialogs'
import history from './history'
import useInterval from './hooks/useInterval'
import { Dispatch, GlobalState } from './models/bootstrap'
import RouteService from './services/RouteService'
import createTheme from './theme'

const useStyles = makeStyles((theme) => ({
	content: {
		maxWidth: theme.breakpoints.width('sm'),
		margin: '0 auto'
	},
	toolbar: theme.mixins.toolbar,
	backgroundWrapper: {
		position: 'relative',
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		'&:after': {
			content: '""',
			background: `url(${Background}) no-repeat left center fixed`,
			backgroundSize: 'cover',
			opacity: 0.2,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			position: 'absolute',
			zIndex: -1
		}
	}
}))

const App = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { currentUser, currentTenant } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser,
		currentTenant: state.tenant.currentTenant
	}))
	const [theme, setTheme] = useState<Theme>(createTheme())

	React.useEffect(() => {
		dispatch.environment.init(history)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Used to update the MuiTheme when an Eco plays with the color change feature. Also used to update Theme when new tenant colors are fetched by other users.
	React.useEffect(() => {
		currentTenant &&
			setTheme(
				createTheme({
					primaryColor: currentTenant?.dirtyPrimaryColor ?? currentTenant?.primaryColor ?? null,
					secondaryColor: currentTenant?.dirtySecondaryColor ?? currentTenant?.secondaryColor ?? null
				})
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentTenant?._id,
		currentTenant?.primaryColor,
		currentTenant?.dirtyPrimaryColor,
		currentTenant?.secondaryColor,
		currentTenant?.dirtySecondaryColor
	])

	useInterval(() => {
		dispatch.user.getNewAccessToken()
	}, 240000)

	/**
	 * This function determines the allowed routes for the current user or if user is not logged in.
	 * If the user is not logged in we only allow the login route and anonymous routes.
	 * Otherwise we map over all routes, exclude login (because you are already logged in) and add a default redirect aswell.
	 * @returns the <Route/> or <Redirect/> components for the current user / no user
	 */
	const getAllowedRouteComponents = () => {
		let routes: JSX.Element[] = []

		if (!currentTenant && !currentUser) {
			routes = RouteService.getAnonymousRoutesWithoutTenant().map((fullRoute) => {
				return <Route exact path={fullRoute.path} component={fullRoute.routeComponent} key={fullRoute.name} />
			})
			routes.push(<Route component={() => <BillboRedirect toLogin />} key='noMatchRedirect' />)
		}

		if (currentTenant && !currentUser) {
			routes = RouteService.getAnonymousRoutes().map((fullRoute) => {
				return <Route exact path={fullRoute.path} component={fullRoute.routeComponent} key={fullRoute.name} />
			})
			routes.push(<Route component={() => <BillboRedirect />} key='noMatchRedirect' />)
		}

		if (currentTenant && currentUser) {
			routes = RouteService.getAll().map((route) => {
				return <Route exact path={route.path} component={route.routeComponent} key={route.name} />
			})
			routes.push(<Route component={() => <BillboRedirect />} key='noMatchRedirect' />)
		}

		if (!currentTenant && currentUser) {
			routes = RouteService.getAllowedWithoutTenant().map((route) => {
				return <Route exact path={route.path} component={route.routeComponent} key={route.name} />
			})
			routes.push(<Route component={() => <BillboRedirect toLogin />} key='noMatchRedirect' />)
		}

		return routes
	}

	if (!currentUser && !RouteService.isOnAnonymousPage()) return <Spinner />

	if (window.location.hostname === 'billbo-but-better.netlify.app')
		return (
			<Typography>
				We moved to <Link href='https://billbo.online'>billbo.online</Link>
			</Typography>
		)

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={5}>
				<BillboAppBar />
				<div className={classes.toolbar}></div>
				<div id='tenantHeaderPortal'></div>
				<div className={classes.backgroundWrapper}>
					<div className={classes.content}>
						<Suspense fallback={<Spinner />}>
							<Router history={history}>
								<Switch>{getAllowedRouteComponents()}</Switch>
							</Router>
						</Suspense>
					</div>
				</div>
				<BillboDialog />
				<BillboSnackbar />
			</SnackbarProvider>
		</ThemeProvider>
	)
}

export default App
