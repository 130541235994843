import { createModel } from '@rematch/core';
import { RootModel } from '.';
import i18n from '../i18n';
import TenantService from '../services/TenantService';
import { Dispatch, GlobalState } from './bootstrap';
import axios from 'axios';
import { merge } from '../helpers/mergeAndSort';
import RouteService, { Routes } from '../services/RouteService';

export interface Tenant {
    _id: string;
    isPublic?: boolean;
    name: string;
    headerContent?: string;
    primaryColor?: string;
    secondaryColor?: string;
    dirtyPrimaryColor?: string;
    dirtySecondaryColor?: string;
}

export interface TenantStats {
    projectCount: number;
    postCount: number;
    userCount: number;
}

export type TenantData = Omit<Tenant, '_id'>;

type TenantState = {
    tenants: Array<Tenant>;
    myTenants: Array<Tenant>;
    currentTenant: Tenant | null;
    stats: TenantStats | null;
}

const tenantService = new TenantService();

const initialState = {
    tenants: [],
    myTenants: [],
    currentTenant: null,
    stats: null
} as TenantState

export const tenant = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setCurrentTenant: (state: TenantState, currentTenant: Tenant) => ({
            ...state,
            currentTenant
        }),
        setTenants: (state: TenantState, tenants: Array<Tenant>) => {
            return {
                ...state,
                tenants: merge(state.tenants, tenants),
            }
        },
        setCurrentTenantDirtyColors: (state: TenantState, payload: { dirtyPrimaryColor: string, dirtySecondaryColor: string }) => ({
            ...state,
            currentTenant: {
                ...state.currentTenant!,
                dirtyPrimaryColor: payload.dirtyPrimaryColor !== undefined ? payload.dirtyPrimaryColor : state.currentTenant!.dirtyPrimaryColor,
                dirtySecondaryColor: payload.dirtySecondaryColor !== undefined ? payload.dirtySecondaryColor : state.currentTenant!.dirtySecondaryColor,
            }
        }),
        setMyTenants: (state: TenantState, myTenants: Array<Tenant>) => ({
            ...state,
            myTenants
        }),
        setTenantStats: (state: TenantState, stats: TenantStats) => ({
            ...state,
            stats
        })
    },
    effects: (dispatch: Dispatch) => ({
        selectCurrentTenant: async (tenantId: string, state: GlobalState) => {
            axios.defaults.headers.common['x-billbo-tenant-id'] = tenantId
            localStorage.setItem('tenantId', tenantId)
            const tenant = await tenantService.getById(tenantId)
            localStorage.setItem('isPublicTenant', `${tenant.isPublic ?? false}`)

            tenant.primaryColor ? localStorage.setItem('tenantPrimaryColor', tenant.primaryColor) : localStorage.removeItem('tenantPrimaryColor')
            tenant.secondaryColor ? localStorage.setItem('tenantSecondaryColor', tenant.secondaryColor) : localStorage.removeItem('tenantSecondaryColor')

            dispatch.story.reset()
            dispatch.project.reset()
            dispatch.tag.reset()

            dispatch.tenant.setCurrentTenant({
                ...tenant,
                dirtyPrimaryColor: state.tenant.currentTenant?.dirtyPrimaryColor,
                dirtySecondaryColor: state.tenant.currentTenant?.dirtySecondaryColor,
            })

            dispatch.environment.navigate(RouteService.getByPath(Routes.home))
        },
        getMyTenants: async (payload: null, state: GlobalState) => {
            const tenants = await tenantService.getMine()

            dispatch.tenant.setMyTenants(tenants)
        },
        getAllTenants: async (payload: null, state: GlobalState) => {
            const tenants = await tenantService.getAll()

            dispatch.tenant.setTenants(tenants)
        },
        getAllPublicTenants: async (payload: void, state: GlobalState) => {
            const tenants = await tenantService.getAllPublic()

            dispatch.tenant.setTenants(tenants)
        },
        getCurrentTenant: async (payload: null, state: GlobalState) => {
            const tid = state.tenant.currentTenant?._id;
            if (!tid) return;
            const tenant = await tenantService.getById(tid)

            dispatch.tenant.setCurrentTenant({
                ...tenant,
                dirtyPrimaryColor: state.tenant.currentTenant?.dirtyPrimaryColor,
                dirtySecondaryColor: state.tenant.currentTenant?.dirtySecondaryColor,
            })
        },
        createTenant: async (tenantData: TenantData, state: GlobalState) => {
            try {
                await tenantService.create(tenantData)
                await dispatch.tenant.getCurrentTenant()
                dispatch.tenant.getAllTenants()

                dispatch.environment.enqueueSnack({ message: `Tenant created: ${tenantData.name}`, options: { variant: 'success' }})
            } catch(e) {
                // @ts-ignore
                dispatch.environment.enqueueSnack({ message: e.response.data.message, options: { variant: 'error' }})
            }
        },
        updateHeaderContent: async (headerContent: string, state: GlobalState) => {
            const tenant = await tenantService.updateHeaderContent(state.tenant.currentTenant._id, headerContent)

            dispatch.tenant.setCurrentTenant(tenant)
        },
        changeName: async (payload: { tenantId: string, newName: string }, state: GlobalState) => {
            try {
                await tenantService.changeName(payload.tenantId, payload.newName)

                dispatch.environment.enqueueSnack({ message: `Tenant name changed to: ${payload.newName}`, options: { variant: 'success' }})
            } catch(e) {
                // @ts-ignore
                dispatch.environment.enqueueSnack({ message: e.response.data.message, options: { variant: 'error' }})
            }
        },
        changeColors: async (payload: { primaryColor?: string, secondaryColor?: string }, state: GlobalState) => {
            try {
                await tenantService.changeColors({ primaryColor: payload.primaryColor, secondaryColor: payload.secondaryColor })
                payload.primaryColor && localStorage.setItem('tenantPrimaryColor', payload.primaryColor)
                payload.secondaryColor && localStorage.setItem('tenantSecondaryColor', payload.secondaryColor)
                dispatch.tenant.setCurrentTenantDirtyColors({ dirtyPrimaryColor: null, dirtySecondaryColor: null })
                dispatch.tenant.getCurrentTenant()

                dispatch.environment.enqueueSnack({ message: i18n.t('tenant.colorsChanged'), options: { variant: 'success' }})
            } catch(e) {
                // @ts-ignore
                dispatch.environment.enqueueSnack({ message: e.response.data.message, options: { variant: 'error' }})
            }
        },
        exportFullFeed: async (payload: void, state: GlobalState) => {
            const html = await tenantService.exportFullFeed();
            const now = new Date();
            let tempEl = document.createElement('a');
            tempEl.href = window.URL.createObjectURL(new Blob([html], { type: 'attachment/text' }))
            tempEl.target = '_blank';
            tempEl.download = `${state.tenant.currentTenant.name}_${now.toLocaleDateString()}_${now.toLocaleTimeString()}_full_feed_export.html`;
            tempEl.style.display = 'none';
            document.body.appendChild(tempEl);
            tempEl.click();
            document.body.removeChild(tempEl);
        },
        getTenantStats: async (payload: void, state: GlobalState) => {
            const stats = await tenantService.getStats();
            dispatch.tenant.setTenantStats(stats);
        },
    })
}) as any;