import axios from 'axios';
import { Project, ProjectData } from '../models/project';

class ProjectService {
    async create(projectData: ProjectData): Promise<string> {
        const response = await axios.post<Project>('/project/create', { ...projectData });

        return response.data._id;
    }

    async getAll(): Promise<Array<Project>> {
        const response = await axios.get<{ projects: Array<Project> }>('/project/');

        return response.data.projects;
    }

    async getById(_id: string): Promise<Project> {
        const response = await axios.get<{ project: Project }>(`/project/${_id}`);

        return response.data.project;
    }

    async getByUserId(userId: string): Promise<Array<Project>> {
        const response = await axios.get<{ projects: Array<Project> }>(`/project/byUserId/${userId}`);

        return response.data.projects;
    }

    async getByCreated(payload: { start: number }): Promise<PagedProjects> {
        let route = '/project/byCreated'
        route += `?start=${payload.start}`
        
        const response = await axios.get(route);

        return response.data;
    }

    async update(projectData: Project): Promise<Project> {
        const response = await axios.put<{ updatedProject: Project }>(`/project/${projectData._id}`, projectData);

        return response.data.updatedProject;
    }
}

interface PagedProjects {
    projects: Project[];
    start: number;
    limit: number;
}

export default ProjectService;