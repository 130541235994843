import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { mergeAndSort } from '../helpers/mergeAndSort';
import i18n from '../i18n';
import ProjectService from '../services/ProjectService';
import { Dispatch, GlobalState } from './bootstrap';
import { Story } from './story';
import { User } from './user';

export interface Project extends ProjectData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface ProjectData {
    name: string;
    description: string;
    user: string|User;
    stories?: Story[];
    tags: string[];
}

type ProjectState = {
    currentProject: Project | null;
    projectsByUserId: {[userId: string]: Array<Project>};
    currentProjectLoading: boolean;
    projectsByCreated: Array<Project>;
    projectsByCreatedHasMore: boolean;
}

const projectService = new ProjectService();

const initialState = {
    currentProject: null,
    projectsByUserId: {},
    currentProjectLoading: false,
    projectsByCreated: [],
    projectsByCreatedHasMore: true,
} as ProjectState

export const project = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setCurrentProject: (state: ProjectState, currentProject: Project) => ({
            ...state,
            currentProject
        }),
        setProjectsByUserId: (state: ProjectState, payload: {userId: string, projects: Project[]}) => ({
            ...state,
            projectsByUserId: {
                ...state.projectsByUserId,
                [payload.userId]: payload.projects
            }
        }),
        setCurrentProjectLoading: (state: ProjectState, currentProjectLoading: boolean) => ({
            ...state,
            currentProjectLoading
        }),
        setProjectsByCreated: (state: ProjectState, newProjects: Project[]) => ({
            ...state,
            projectsByCreated: mergeAndSort(state.projectsByCreated, newProjects)
        }),
        setProjectsByCreatedHasMore: (state: ProjectState, hasMore: boolean) => ({
            ...state,
            projectsByCreatedHasMore: hasMore
        }),
    },
    effects: (dispatch: Dispatch) => ({
        createProject: async (projectData: ProjectData, state: GlobalState) => {
            try {
                await projectService.create(projectData)
                dispatch.environment.enqueueSnack({message: i18n.t('project.created'), options: { variant: 'success' }})
                dispatch.project.getProjectsByUserId({})
                dispatch.project.getProjectsByCreated(true)
            } catch(e) {
                dispatch.environment.enqueueSnack({message: i18n.t('project.creationFailed'), options: { variant: 'error' }})
            }
        },
        getAllProjects: async (payload: null, state: GlobalState) => {
            const projects = await projectService.getAll()

            dispatch.project.setProjects(projects)
        },
        getProjectsByUserId: async (payload: { userId?: string }, state: GlobalState) => {
            const userId = payload.userId ?? state.user.currentUser._id
            const projects = await projectService.getByUserId(userId)

            dispatch.project.setProjectsByUserId({ userId, projects });
        },
        getProject: async (_id: string, state: GlobalState) => {
            dispatch.project.setCurrentProjectLoading(true)
            const project = await projectService.getById(_id)
            dispatch.project.setCurrentProjectLoading(false);
            dispatch.project.setCurrentProject(project)
        },
        getProjectsByCreated: async (fromStart: boolean, state: GlobalState) => {
            const response = await projectService.getByCreated({ start: fromStart ? 0 : state.project.projectsByCreated.length })
            response.projects.length < response.limit && dispatch.project.setProjectsByCreatedHasMore(false)
            dispatch.project.setProjectsByCreated(response.projects)
        },
        updateProject: async (updateProject: Project, state: GlobalState) => {
            const updatedProject = await projectService.update(updateProject)
            dispatch.project.setProjectsByUserId({
                userId: state.user.currentUser._id,
                projects: state.project.projectsByUserId[state.user.currentUser._id].map((p: Project) => p._id === updatedProject._id ? updatedProject : p)
            })
        }
    })
}) as any;