import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import { createMemoryHistory } from "history";
import { models } from ".";

export const store = init({
    name: 'Billbo store',
    models
})

export type Store = typeof store;
export type Dispatch = RematchDispatch<typeof models>;
export type GlobalState = RematchRootState<typeof models>;
export const memoryHistory = createMemoryHistory();