import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from './locales/de.json';
import en from './locales/en.json';

const resources = {
  en,
  de
};

export const availableLanguages = ['en', 'de']

const userLanguagePref = localStorage.getItem('userLanguagePref')

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: userLanguagePref ?? navigator.language ?? 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;