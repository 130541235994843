import { AccountCircle, Deck, Eco, Home, LockOpen, Settings, Group, Copyright, Policy } from '@material-ui/icons'
import React from 'react'
import { Role } from '../models/user'

const ProjectsPage = React.lazy(() => import('../pages/ProjectsPage'))
const ProfilePage = React.lazy(() => import('../pages/ProfilePage'))
const HomePage = React.lazy(() => import('../pages/HomePage'))
const LoginPage = React.lazy(() => import('../pages/LoginPage'))
const EcoPage = React.lazy(() => import('../pages/EcoPage'))
const AdminPage = React.lazy(() => import('../pages/AdminPage'))
const RegisterPage = React.lazy(() => import('../pages/RegisterPage'))
const RecoverAccountPage = React.lazy(() => import('../pages/RecoverAccountPage'))
const SelectTenantPage = React.lazy(() => import('../pages/SelectTenantPage'))
const DataProtectionPage = React.lazy(() => import('../pages/DataProtectionPage'))
const ImprintPage = React.lazy(() => import('../pages/ImprintPage'))

export enum Routes {
	login = '/login',
	home = '/',
	projects = '/projects',
	admin = '/admin',
	eco = '/eco',
	profile = '/profile',
	register = '/register',
	recovery = '/recovery',
	selectTenant = '/selectTenant',
	selectPublicTenant = '/selectPublicTenant',
	dataProtection = '/dataProtection',
	imprint = '/imprint',
}

export interface FullRoute {
	path: Routes
	name: string
	permissions: Array<Role>
	routeType: RouteTypes
	iconComponent: React.FC
	routeComponent: React.FC
	anonymous?: boolean,
	host?: 'billbo.online'
}

export enum RouteTypes {
	drawer = 'drawer',
	userMenu = 'userMenu',
	hidden = 'hidden'
}

class RouteService {
	fullRoutes: Array<FullRoute> = [
		{
			path: Routes.login,
			name: 'Login',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.hidden,
			iconComponent: LockOpen,
			routeComponent: LoginPage
		},
		{
			path: Routes.home,
			name: 'Home',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.drawer,
			iconComponent: Home,
			routeComponent: HomePage
		},
		{
			path: Routes.projects,
			name: 'Projects',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.drawer,
			iconComponent: Deck,
			routeComponent: ProjectsPage
		},
		{
			path: Routes.eco,
			name: 'Eco',
			permissions: [Role.eco, Role.admin],
			routeType: RouteTypes.drawer,
			iconComponent: Eco,
			routeComponent: EcoPage
		},
		{
			path: Routes.admin,
			name: 'Admin',
			permissions: [Role.admin],
			routeType: RouteTypes.drawer,
			iconComponent: Settings,
			routeComponent: AdminPage
		},
		{
			path: Routes.profile,
			name: 'Profile',
			permissions: [Role.user, Role.admin, Role.eco],
			routeType: RouteTypes.userMenu,
			iconComponent: AccountCircle,
			routeComponent: ProfilePage
		},
		{
			path: Routes.register,
			name: 'Register',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.hidden,
			iconComponent: LockOpen,
			routeComponent: RegisterPage
		},
		{
			path: Routes.recovery,
			name: 'Recover',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.hidden,
			iconComponent: LockOpen,
			routeComponent: RecoverAccountPage
		},
		{
			path: Routes.selectTenant,
			name: 'SelectTenant',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.drawer,
			iconComponent: Group,
			routeComponent: SelectTenantPage
		},
		{
			path: Routes.dataProtection,
			name: 'DataProtection',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.drawer,
			iconComponent: Policy,
			routeComponent: DataProtectionPage,
			host: 'billbo.online'
		},
		{
			path: Routes.imprint,
			name: 'Imprint',
			permissions: [],
			anonymous: true,
			routeType: RouteTypes.drawer,
			iconComponent: Copyright,
			routeComponent: ImprintPage,
			host: 'billbo.online'
		}
	]

	getByPath(path: Routes): FullRoute {
		const matchedRoute = this.fullRoutes.find((route) => route.path === path)
		return matchedRoute ?? this.fullRoutes.find((route) => route.path === Routes.home)!
	}

	getByName(name: string): FullRoute {
		const matchedRoute = this.fullRoutes.find((route) => route.name === name)
		return matchedRoute ?? this.fullRoutes.find((route) => route.path === Routes.home)!
	}

	getAll(routeType?: RouteTypes) {
		const hostGuardedRoutes = this.fullRoutes.filter((r) => r.host ? r.host === window.location.host : true)
		return routeType ? hostGuardedRoutes.filter((r) => r.routeType === routeType) : hostGuardedRoutes
	}

	getAllowed(role?: Role, routeType?: RouteTypes): Array<FullRoute> {
		let routes: Array<FullRoute> = []

		if (!role)
			routes = [
				this.fullRoutes.find((route) => route.path === Routes.login)!,
				...this.fullRoutes.filter((r) => r.permissions.length === 0 || r.anonymous)
			]
		if (role) routes = this.fullRoutes.filter((route) => route.permissions.some((p) => p === role) || route.permissions.length === 0)
		if (routeType) routes = routes.filter((r) => r.routeType === routeType)

		const hostGuardedRoutes = routes.filter((r) => r.host ? r.host === window.location.host : true)
		return hostGuardedRoutes
	}

	getAllowedWithoutTenant() {
		const hostGuardedRoutes = this.fullRoutes.filter((r) => r.host ? r.host === window.location.host : true)
		return hostGuardedRoutes.filter(
			(fullRoute) =>
				fullRoute.path === Routes.profile ||
				fullRoute.path === Routes.login ||
				fullRoute.path === Routes.recovery ||
				fullRoute.path === Routes.register ||
				fullRoute.path === Routes.selectTenant ||
				fullRoute.path === Routes.dataProtection ||
				fullRoute.path === Routes.imprint
		)
	}

	getAnonymousRoutes() {
		const hostGuardedRoutes = this.fullRoutes.filter((r) => r.host ? r.host === window.location.host : true)
		return hostGuardedRoutes.filter((fullRoute) => fullRoute.anonymous)
	}

	getAnonymousRoutesWithoutTenant() {
		const hostGuardedRoutes = this.fullRoutes.filter((r) => r.host ? r.host === window.location.host : true)
		return hostGuardedRoutes.filter(
			(fullRoute) =>
				fullRoute.path === Routes.login ||
				fullRoute.path === Routes.recovery ||
				fullRoute.path === Routes.register ||
				fullRoute.path === Routes.selectTenant ||
				fullRoute.path === Routes.dataProtection ||
				fullRoute.path === Routes.imprint
		)
	}

	isOnAnonymousPage(): boolean {
		const hostGuardedRoutes = this.fullRoutes.filter((r) => r.host ? r.host === window.location.host : true)
		return hostGuardedRoutes.some((fullRoute) => window.location.pathname.startsWith(fullRoute.path))
	}
}

export default new RouteService()
