import { Avatar, makeStyles, Typography } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";
import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dispatch } from "../models/bootstrap";
import { Dialogs } from "../models/dialog";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer',
    },
    pulse: {
        animation: `$pulse 2s infinite`
    },
    '@keyframes pulse': {
        '0%': {
          transform: 'scale(0.97)',
          boxShadow: `0 0 0 0 ${lighten(theme.palette.secondary.main, .1)}`
        },
        '70%': {
          transform: 'scale(1)',
          boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
        },
        '100%': {
          transform: 'scale(0.97)',
          boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
        }
    },
    large: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    userName: {
      paddingLeft: theme.spacing(1),
      cursor: 'pointer'
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center'
    }
}));

interface BillboAvatarProps {
    user: { 
      _id?: string;
      displayName: string;
      avatar?: string;
      [x: string]: any;
    };
    pulse?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    large?: boolean;
    showName?: boolean;
}

const BillboAvatar: React.FC<BillboAvatarProps> = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch<Dispatch>()
    const { t } = useTranslation()

    // Handle deleted users.
    const user = props.user ?? { displayName: t('user.deletedUser'), _id: undefined, avatar: undefined }

    const handleClickDefault = () => dispatch.dialog.openDialog({ dialog: Dialogs.viewUser, entityId: user._id })
    
    return <span onClick={props.user ? (props.onClick ?? handleClickDefault) : undefined} className={classes.wrapper}>
      <Avatar 
        alt={user.displayName}
        className={`${classes.avatar} ${props.pulse && classes.pulse} ${props.large && classes.large}`}
        src={user.avatar ?? undefined}
      />
      {props.showName && <Typography variant='caption' className={classes.userName}>
        {user.displayName}
      </Typography>}
    </span>
}

export default BillboAvatar